import Slider from "react-slick";
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
import Head from 'next/head';
import { createUseStyles } from "react-jss";
import { useState } from "react";

const useStyles = createUseStyles({
    container: {
        display: "flex",
        alignItems: "center",
    },
    sliderContainer: {
        // margin: '-15px auto',
        // display: "flex",
        // // flexWrap: "no-wrap",
        // overflowX: 'auto',
        // overflowY: 'hidden',
        // scrollSnapType: 'x mandatory',
        // scrollBehavior: 'smooth',
        // width: '-webkit-fill-available',
        // '&::-webkit-scrollbar': {
        //     display: 'none',
        // }
    },
    item: {
        width: '33.33%',
        // margin: '0 20px',
        scrollSnapAlign: "start",
    },
    chevronIcon: {
        '& svg': {
            fontSize: '64px!important',
            cursor: 'pointer',
        }
    },
    smScroll: {
        display: 'none',
    },
    tracker: {},
    activeTracker: {},
    '@media screen and (max-width:760px)': {
        container: {
            flexDirection: 'column',
        },
        sliderContainer: {
            width: '100%',
            margin: "15px 0",
        },
        item: {
            width: '100%',
            flexShrink: 0,
            margin: 0,
            display: 'flex',
            justifyContent: 'center',
        },
        chevronIcon: {
            display: 'none',
        },
        smScroll: {
            display: 'flex',
            margin: '10px 0'
        },
        tracker: {
            height: '15px',
            width: '15px',
            backgroundColor: '#fce3b4',
            borderRadius: 50,
            margin: 5,
        },
        activeTracker: {
            backgroundColor: '#f3a02b',
        }
    }
})


function SlickSlider({
    data = [],
    CardComponent,
    slidesToShow = 3,
    autoplay = false,
    autoplaySpeed = 5000,
    centered = false,
}) {

    const classes = useStyles();
    const [autoPlay, setAutoPlay] = useState(autoplay);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        autoplay: autoPlay,
        autoplaySpeed: autoplaySpeed,
        pauseOnFocus: true,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 950,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    if (centered) {
        settings["centerPadding"] = "60px";
        settings["className"] = "center";
        settings["centerMode"] = true;
    }

    return (
        <>
            <Head>
                <link
                    rel="stylesheet"
                    type="text/css"
                    // charset="UTF-8"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                />
                <link
                    rel="stylesheet"
                    type="text/css"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                />
            </Head>
            <Slider
                className={classes.sliderContainer}
                {...settings}
            >
                {
                    data.map((item, index) => (
                        <div
                            key={index}
                            id={`data-${index}`}
                        >
                            <CardComponent
                                data={item}
                                autoplay={autoplay}
                                setAutoPlay={setAutoPlay}
                            />
                        </div>
                    ))
                }
            </Slider>
        </>
    )
}

export default SlickSlider;